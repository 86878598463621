var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "iconfont icon-fanhui" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "contractSign" })
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "180px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "合同信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同编号" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.contractId,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同名称" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.contractName,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同模板" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.templateName,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.appForm.produceInfoVo.creditCode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属授信" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.produceInfoVo.creditCode,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.appForm.produceInfoVo.useCode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属用信" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.produceInfoVo.useCode,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签署方" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "table-input",
                              attrs: {
                                data: _vm.appForm.signInfoList,
                                "max-height": "380px"
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "signatory",
                                  label: "签署方",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "natureSignatory",
                                  label: "签署方性质",
                                  "min-width": "100",
                                  formatter: function(row, col, cell) {
                                    return _vm.utils.statusFormat(
                                      String(cell),
                                      "companyType"
                                    )
                                  },
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orgName",
                                  label: "签署方名称",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "signWordModel",
                                  label: "签章字样",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "editor-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "contractContent",
                              label: "合同内容"
                            }
                          },
                          [
                            _c("div", { staticClass: "editor-container" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.appForm.produceInfoVo.contractContent,
                                    expression:
                                      "appForm.produceInfoVo.contractContent"
                                  }
                                ],
                                attrs: { id: "editor" },
                                domProps: {
                                  value:
                                    _vm.appForm.produceInfoVo.contractContent
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.appForm.produceInfoVo,
                                      "contractContent",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _vm.appForm.produceInfoVo.signingMethod === 1 &&
                  [0, 2].includes(_vm.appForm.produceInfoVo.signState) &&
                  _vm.signInfo.signState === 0
                    ? _c("ics-button-inner", {
                        attrs: {
                          loading: _vm.loading.submit,
                          "submit-title": "我已阅读并同意签署",
                          "show-cancel": false
                        },
                        on: { submit: _vm.signAuthentication }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
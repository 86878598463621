<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button icon="iconfont icon-fanhui" @click="$router.push({ name: 'contractSign' })">
          返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="合同信息">
          <el-col :span="12">
            <el-form-item label="合同编号">
              <ics-item-inner :prop="appForm.produceInfoVo.contractId" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="合同名称">
              <ics-item-inner :prop="appForm.produceInfoVo.contractName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同模板">
              <ics-item-inner :prop="appForm.produceInfoVo.templateName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="appForm.produceInfoVo.creditCode" :span="12">
            <el-form-item label="所属授信">
              <ics-item-inner :prop="appForm.produceInfoVo.creditCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="appForm.produceInfoVo.useCode" :span="12">
            <el-form-item label="所属用信">
              <ics-item-inner :prop="appForm.produceInfoVo.useCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="签署方">
              <el-table :data="appForm.signInfoList" class="table-input" max-height="380px">
                <el-table-column prop="signatory" label="签署方" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
                <el-table-column prop="natureSignatory" label="签署方性质" min-width="100" :formatter="(row, col, cell) => utils.statusFormat(String(cell), 'companyType')" show-overflow-tooltip />
                <el-table-column prop="orgName" label="签署方名称" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
                <el-table-column prop="signWordModel" label="签章字样" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
              </el-table>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="editor-content">
              <el-form-item prop="contractContent" label="合同内容">
                <div class="editor-container">
                  <textarea id="editor" v-model="appForm.produceInfoVo.contractContent" />
                </div>
              </el-form-item>
            </div>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="" :show-header="true">
          <ics-button-inner v-if="appForm.produceInfoVo.signingMethod === 1 && [0, 2].includes(appForm.produceInfoVo.signState) && signInfo.signState === 0" :loading="loading.submit" submit-title="我已阅读并同意签署" :show-cancel="false" @submit="signAuthentication" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import CKEDITOR from 'CKEDITOR'
import IcsButtonInner from "../../../components/button-inner";
import IcsPageInner from "../../../components/page-inner";
export default {
  components: {IcsPageInner, IcsButtonInner},
  mixins: [routeEnterMixin],
  data() {
    return {
      contractId: this.$route.query.contractId,
      appForm: {
        produceInfoVo: {
          contractContent: ''
        },
        signInfoList: []
      },
      signInfo: {},
      dragVal: '',
      // 富文本编辑器默认内容
      content: '<h2>请输入内容..</h2>',
      // 富文本编辑器配置
      editorOption: {
        // Some Quill options...
      },
    }
  },
  created() {
    setTimeout(() => {
      this.editorCkeditor()
    }, 100)
  },
  methods: {
    editorCkeditor () {
      this.editor = CKEDITOR.replace('editor', {
        removeButtons: 'Maximize,Source,Templates,About,CreateDiv,Language,BidiRtl,BidiLtr,Flash,Smiley,SpecialChar,Iframe,PageBreak,HiddenField,ImageButton,Button,Textarea,TextField,Select,Radio,Checkbox,SelectAll,Save,NewPage,ExportPdf,Preview,Print,Blockquote,ShowBlocks',
        image_previewText: ' ',
        isReadOnly: true,
        bodyClass: 'document-editor',
        filebrowserImageUploadUrl: this.filebrowserImageUploadUrl,
        width: 940,
        height: 420
      })
      CKEDITOR.addCss(
        'body.document-editor { margin: 0.5cm auto; border: 1px #D3D3D3 solid; border-radius: 5px; background: white; box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }' +
        'body.document-editor, div.cke_editable { width: 570px; padding: 1cm 2cm 2cm; } ' +
        'body.document-editor table td > p, div.cke_editable table td > p { margin-top: 0; margin-bottom: 0; padding: 4px 0 3px 5px;} ' +
        'blockquote { font-family: sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; } ');
      CKEDITOR.on('instanceReady', (e) => {
        if (this.contractId) {
          this.getDetail()
        }
        this.editor.setReadOnly(true)
      })
    },
    getDetail () {
      this.loading.detail = false
      this.api.contract.sign.getDetail(this.contractId).then(result => {
        this.appForm = result.data.data
        this.editor.setData(this.appForm.produceInfoVo.contractContent)
        this._.filter(this.appForm.signInfoList,{natureSignatory: `${process.env.VUE_APP_PLATFORM_BUSINESS}`}).forEach(item => {
          this.signInfo = item
        })
        console.log(this.signInfo)
      }).finally(() => {
        this.loading.detail = false
      })
    },
    signAuthentication () {
      this.api.cfcaSign.isContractAccount().then(result => {
        if (!result.data.data) {
          this.$confirm('暂未开通电子签署服务，是否确认开通, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({name: 'signAdministrators', query: { contractId: this.contractId }})
          })
        } else {
          this.$confirm('使用本企业的电子签章签署合同时需要进行身份验证, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({name: 'signAuthentication', query: { contractId: this.contractId }})
          })
        }
      }).finally(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>
